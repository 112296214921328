<template>
  <div :class="$bem()">
    <div :class="$bem('row')">
      <div :class="$bem('aside')">
        <div :class="$bem('filter')">
          <filters />
        </div>
      </div>

      <div v-if="mobileOnly" :class="$bem('main')">
        <mobile-header :objectsCount="objects.length" :totalCount="count" />

        <template v-if="isLoaded">
          <div v-if="objects && objects.length" :class="$bem('cards-mobile')">
            <ul :class="$bem('cards-mobile-list')">
              <li v-for="apt in objects" :key="apt.id">
                <catalog-card :apartment="apt" />
              </li>
            </ul>

            <infinite-loading @infinite="infiniteHandler">
              <div slot="spinner" />
            </infinite-loading>
          </div>

          <div v-else :class="$bem('no-results')">
            <img
              src="@/assets/images/no-results-01.webp"
              alt="EasyBase | No search results"
              width="320"
              height="320"
            />
          </div>
        </template>
      </div>

      <div v-if="desktopOnly" :class="$bem('main')">
        <template v-if="isLoaded">
          <ul
            v-if="objects && objects.length"
            ref="cardsDesktop"
            :class="$bem('cards')"
          >
            <li v-for="apt in objects" :key="apt.id">
              <catalog-card :apartment="apt" />
            </li>
          </ul>

          <div v-else :class="$bem('no-results')">
            <img
              src="@/assets/images/no-results-01.webp"
              alt="EasyBase | No search results"
              width="320"
              height="320"
            />
          </div>

          <SearchResultsCounter
            :count="objects.length"
            :general="count"
            :class="$bem('search-results')"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import component from '@/mixins/component';
import mediaQueries from '@/mixins/mediaQueries';
import { mapState, mapActions, mapMutations } from 'vuex';
import { NOTIFICATIONS } from '@/helpers/constants';
import SearchResultsCounter from '@/components/app/SearchResultsCounter.vue';

export default {
  name: 'catalog',
  components: {
    Filters: () => import('@/components/app/Filters'),
    MobileHeader: () => import('@/components/app/mobile-header'),
    CatalogCard: () => import('@/components/app/CatalogCard'),
    InfiniteLoading: () => import('vue-infinite-loading'),
    SearchResultsCounter
  },
  props: {
    isArchive: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isLoaded: false
    };
  },
  computed: {
    ...mapState({
      objects: (state) => state.main.objects,
      count: (state) => state.main.count,
      page: (state) => state.main.page,
      loaderObject: (state) => state.main.loaderObject
    })
  },
  methods: {
    ...mapActions(['getObjects']),
    ...mapMutations(['SET_PAGE']),
    scrollToEnd() {
      if (!this.page) {
        this.$notify({
          group: 'app',
          type: 'success',
          text: NOTIFICATIONS.viewedAllObjects,
          ignoreDuplicates: true
        });
        return;
      }
      this.getObjects();
    },
    async infiniteHandler($state) {
      if (this.$store.state.main.page) {
        if (this.$store.state.main.page === 1) {
          await this.getObjects({ isSearchById: false, getLastFilter: true });
        } else {
          await this.getObjects();
        }
        $state.loaded();
      }
    },
    setupHorizontalScroll(event) {
      if (this.$refs.cardsDesktop) {
        if (!event.target.closest('.product-modal, .partner-review-wrapper')) {
          // Добавляем горизонтальное движение
          this.$refs.cardsDesktop.scrollLeft += event.deltaX / 2;

          // Обработка вертикального движения как раньше
          this.$refs.cardsDesktop.scrollLeft += event.deltaY / 2;
          event.preventDefault();
        }
      }
    },
    checkScrollPosition(event) {
      let target = event.target;
      // Проверка, что блок прокручен до конца
      if (
        target.scrollLeft + target.offsetWidth + 300 >= target.scrollWidth &&
        !this.loaderObject
      ) {
        this.scrollToEnd();
      }
    }
  },
  async mounted() {
    this.SET_PAGE(1);
    await this.getObjects({ isSearchById: false, getLastFilter: true });
    this.isLoaded = true;

    this.$nextTick(() => {
      if (this.$refs.cardsDesktop) {
        this.$refs.cardsDesktop.addEventListener(
          'wheel',
          this.setupHorizontalScroll
        );
        this.$refs.cardsDesktop.addEventListener(
          'scroll',
          this.checkScrollPosition
        );
      }
    });
  },
  beforeDestroy() {
    if (this.$refs.cardsDesktop) {
      this.$refs.cardsDesktop.removeEventListener(
        'wheel',
        this.setupHorizontalScroll
      );
      this.$refs.cardsDesktop.removeEventListener(
        'scroll',
        this.checkScrollPosition
      );
    }
  },
  mixins: [component, mediaQueries]
};
</script>

<style lang="sass">
.catalog
  min-width: 0

  &__row
    display: flex
    flex: 0 0 100%
    min-width: 0

    @include media-breakpoint-down(md)
      flex-direction: column

  &__aside
    flex: 0 0 var(--catalog-filter-width)
    max-width: var(--catalog-filter-width)

    @include media-breakpoint-down(md)
      display: none

  &__filter
    position: relative
    display: flex
    flex-direction: column
    min-height: 100%
    padding-top: 10px
    box-shadow: 0 0 15px 5px rgb(21, 51, 80)

    @include media-breakpoint-down(md)
      display: none

    .filters
      flex: 1 1 auto
      display: flex
      flex-direction: column

  &__main
    position: relative
    display: flex
    flex-direction: column
    flex: 0 0 calc(100% - var(--catalog-filter-width))
    max-width: calc(100% - var(--catalog-filter-width))
    min-width: 0
    padding: 1rem 1rem 0.5rem 1rem

    @include media-breakpoint-down(md)
      flex: 0 0 100%
      max-width: 100%
      padding: 0
      padding-top: 3rem

  &__no-results
    flex: 0 0 100%
    width: 100%
    display: flex
    align-items: center
    justify-content: center
    padding: 2rem

    img
      max-width: 100%
      max-height: 100%
      object-fit: contain

      @include media-breakpoint-down(md)
        width: 15rem
        height: 15rem

  &__cards-mobile
    display: flex
    flex-direction: column
    margin: 0
    padding: 0

  &__cards-mobile-list
    list-style: none
    padding-left: 0

  &__cards
    list-style: none
    display: flex
    flex-wrap: nowrap
    flex-direction: row
    gap: 0.75rem var(--catalog-list-gap)
    min-width: 0
    margin: 0
    padding: 0
    padding-bottom: 1.5rem
    overflow: auto

    &::-webkit-scrollbar
      position: absolute
      height: var(--catalog-list-scrollbar-height)

    &::-webkit-scrollbar-thumb
      background-color: rgba(78, 111, 144, 0.5)
      border-radius: 0.625rem

    &::-webkit-scrollbar-track
      background-color: rgba(56,84,106,.2)
      border-radius: 0.625rem

  &__search-results
    position: absolute
    z-index: 9
    left: 1rem
    bottom: 0.5rem
</style>
